export default {
  mounted() {
    this.detectCloseFullScreen()
  },
  data: () => ({
    is_full_screen: false
  }),
  methods: {
    enterToFullscreen(element) {
      const el = document.getElementById(element);
      el.width = window.innerWidth;
      el.height = window.innerHeight;
      el.requestFullscreen()
    },
    detectCloseFullScreen() {
      if (document.addEventListener) {
        document.addEventListener('fullscreenchange', this.exitHandler, false);
        document.addEventListener('mozfullscreenchange', this.exitHandler, false);
        document.addEventListener('MSFullscreenChange', this.exitHandler, false);
        document.addEventListener('webkitfullscreenchange', this.exitHandler, false);
      }
    },
    exitHandler(event) {
      this.is_full_screen = window.innerHeight === screen.height
    },
  },
  computed: {
    isFullScreenMode() {
      return this.is_full_screen
    }
  }
}
