<template>
  <b-container class="main-content-container px-4 py-2" fluid>
    <b-row>
      <b-col class="d-flex justify-content-center">
        <b-button-group>
          <router-link id="software" class="btn btn-outline-black"
                       :to="{'name': 'reports-software'}">Software orders
          </router-link>
          <router-link id="credits" class="btn btn-outline-black"
                       :to="{'name': 'reports-credit'}">Credits purchases
          </router-link>
          <router-link id="users" class="btn btn-outline-black"
                       :to="{'name': 'reports-user'}">User management
          </router-link>
        </b-button-group>
      </b-col>
    </b-row>
    <b-card class="mt-2" id="reports">
      <router-view/>
    </b-card>
  </b-container>
</template>

<script>

import fullscreen from "@/utils/fullscreen";

export default {
  name: "index",
  components: {},
  mixins:[fullscreen],
}
</script>

<style lang="scss">
.reports_section_summary {
  height: 35vw;
  width: 100% !important;
  overflow-y: scroll;
  border: 1px solid #e1e5eb;
  background-color: white !important;
}

.reports_section_summary_sales {
  height: 35vw;
  width: 100% !important;
  border: 1px solid #e1e5eb;
  background-color: white !important;
}


.reports_section_graphic {
  height: 35vw;
  width: 100% !important;
  overflow-y: scroll;
  border: 1px solid #e1e5eb;
  background-color: white !important;
}

.reports_section_full_screen {
  height: 100%;
  width: 100%;
}
.reports_pointer {
  cursor: pointer;
}
.reports_columns_active {
  background-color: black;
  color: white;
}
.reports_dot {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  display: inline-block;
}

.reports-fixed-header {
  background-color: white;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
}

@media only screen and (max-width: 600px) {
  .reports_section_summary {
    height: 40rem;
  }
  .reports_section_graphic {
    min-height: 10rem;
    height: 100%;
  }
}
</style>
